@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 270px) {
    .hide-on-small {
      display: none;
    }
  }

  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }

*{
  scroll-behavior: smooth;
}